


<template>
  <div class="hidden md:block flex flex-col min-h-screen px-0 overflow-y-auto relative custom-width"
    style="background-color: #232b36">
    <div class="text-gray-100 text-xl bg-gradient-to-l from-gray-700 via-gray-900 to-black hidden md:block" style="
        background: transparent
          linear-gradient(270deg, #232b36 0%, #151920 100%) 0% 0% no-repeat
          padding-box;
        opacity: 1;
      ">
      <div class="h-56 mt-1 flex items-center justify-center">
        <div class="block">
          <router-link to="/">
            <img src="@/assets/logo/abrof-logo.svg" class="object-cover" alt="logo" width="100" height="100" />
            <img src="@/assets/logo/abrof-slogan.svg" class="object-cover" alt="logo" width="100" height="100" />
          </router-link>
        </div>
      </div>
    </div>
    <div class="">
      <ul class="mt-6 divide-gray-600" dir="rtl">
        <li class="flex text-white divide-y-200">
          <div :class="currentRouteName == 'home' ? 'bg-nava-a' : ''" class="list-item" style="height: 50px"></div>
          <router-link to="/" class="flex space-x-4 px-3 py-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5"
              :stroke="currentRouteName == 'home' ? '#C43670' : 'white'" class="w-6 h-6 ml-3" fill="none">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
            </svg>

            <span class="span-title" :class="currentRouteName == 'home' ? 'text-nav-a' : ''">اكتشف</span>
          </router-link>
        </li>
        <hr class="w-10/12 mr-4" />
        <li class="flex items-center px-0 text-white p-1">
          <div :class="currentRouteName == 'categories' ? 'bg-nava-a' : ''" class="list-item" style="height: 50px"></div>
          <router-link to="/categories" class="flex space-x-4 px-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              :stroke="currentRouteName == 'categories' ? '#C43670' : 'white'" class="w-6 h-6 ml-3">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z" />
            </svg>

            <span class="span-title" :class="currentRouteName == 'categories' ? 'text-nav-a' : ''">التصنيفات</span>
          </router-link>
        </li>
        <hr class="w-10/12 mr-4" />
        <li class="flex items-center px-0 text-white p-1">
          <div :class="currentRouteName == 'artists' ? 'bg-nava-a' : ''" class="list-item" style="height: 50px"></div>
          <router-link to="/artists" class="flex space-x-4 px-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              :stroke="currentRouteName == 'artists' ? '#C43670' : 'white'" class="w-6 h-7 ml-3">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M9 9l10.5-3m0 6.553v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 11-.99-3.467l2.31-.66a2.25 2.25 0 001.632-2.163zm0 0V2.25L9 5.25v10.303m0 0v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 01-.99-3.467l2.31-.66A2.25 2.25 0 009 15.553z" />
            </svg>

            <span class="span-title" :class="currentRouteName == 'artists' ? 'text-nav-a' : ''">الفنانون</span>
          </router-link>
        </li>
        <hr class="w-10/12 mr-4" />
        <li v-if="getStatus" class="flex items-center px-0 text-white p-1 hidden">
          <div :class="currentRouteName == 'libarary' ? 'bg-nava-a' : ''" class="list-item" style="height: 50px"></div>
          <router-link v-show="getStatus" to="/libarary" class="flex space-x-1 px-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5"
              :stroke="currentRouteName == 'libarary' ? '#C43670' : 'white'" fill="none" class="w-6 h-6 ml-3">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
            </svg>

            <span class="span-title" :class="currentRouteName == 'libarary' ? 'text-nav-a' : ''">مكتبتي</span>
          </router-link>
        </li>
        <hr class="w-10/12 mr-4" />
        <li class="flex items-center px-0 text-white p-1">
          <div :class="currentRouteName == 'profile' ? 'bg-nava-a' : ''" class="list-item" style="height: 50px"></div>
          <router-link to="/profile" class="flex space-x-1 px-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24 " stroke-width="1.5"
              :stroke="currentRouteName == 'profile' ? '#C43670' : 'white'" class="w-6 h-6 ml-3">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <span class="span-title" :class="currentRouteName == 'profile' ? 'text-nav-a' : ''">الإعدادات</span>
          </router-link>
        </li>
        <hr class="w-10/12 mr-4" />
      </ul>

      <div class="hidden md:block" dir="rtl">
        <div class="absolute bottom-0 left-0 w-full divide-y divide-gray-700">
          <div class="block sec-color" v-if="getStatus == 'undefined' ||
            getStatus == false ||
            getStatus == null
            ">
            <div class="h-16 flex justify-center items-center text-center ">
              <p class="text-gray-100 lg:px-6 md:px-2 md:text-sm lg:text-md">


                اشترك الآن واستمتع بمزيج من أجمل الأغاني السودانية، اسمع كل جديد وحصري الفن السوداني باشتراك واحد وبدون
                إعلانات
              </p>
            </div>
            <router-link to="/subscribe">
              <button class="primary-color rounded-[25px] py-1 px-0 mb-4 mx-4 w-24 text-white">
                إشترك الأن
              </button>
            </router-link>
          </div>

          <div class="h-24 flex justify-center items-center">
            <p class="text-gray-600 text-md">© 2022 الحقوق محفوظة ل أبروف</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth";
import { mapActions, mapState } from "pinia";

export default {
  data() {
    return {
      showSubscribtionMod: false,
      showSettings: true,
      menuItems: ["/", "/artists", "/categoreis", "/libarary"],
    };
  },
  computed: {
    ...mapState(useAuthStore, ["getStatus"]),
    currentRouteName() {
      return this.$route.name;
    },
  },

  watch: {
    getStatus: {
      immediate: true,
      deep: true,
      /* eslint-disable */
      handler(newValue, oldValue) {
      },
    },
  },

  methods: {
    ...mapActions(useAuthStore, [
      "subscribeAction",
      "checkSubescriptionStatus",
    ]),
    async subscribe() {
      await this.subscribeAction();
    },
  },
  created() {
    this.currentRouteName;
  },
};
</script>

<style scoped>
.sec-color {
  background-color: #72256d;
}

.primary-color {
  background-color: #c43670;
}

.custom-width {
  width: 22.6%;
}

@media screen and (max-width: 1300px) {
  .custom-width {
    width: 30.6%;
  }
}
</style>