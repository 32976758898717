

<template>
  <!-- component -->
  <div class="md:hidden w-full mt-4 ">
    <!-- <section id="bottom-navigation" class="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow"> // if shown only tablet/mobile-->
    <section
      id="bottom-navigation"
      class="block fixed inset-x-0 bottom-0 z-10 pt-2.5"
      style="background-color: #232b36"
    >
      <div id="tabs" class="flex justify-between mx-4" dir="rtl">
        <router-link to="/" class="router-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            class="h-6 w-6 inline-block mb-1"
            viewBox="0 0 24 24"
            :stroke="currentRouteName == 'home' ? '#C43670' : '#fff'"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
            />
          </svg>

          <span
            class="tab tab-kategori block text-sm text-white mt-"
            :class="currentRouteName == 'home' ? 'text-nav-a' : ''"
            >إكتشف</span
          >
          <div
            v-show="currentRouteName == 'home'"
            class="bg-nava-a nav-tab"
          ></div>
        </router-link>
        <router-link to="/search/results" class="router-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block mb-1"
            fill="none"
            viewBox="0 0 24 24"
            :stroke="currentRouteName == 'search.resutls' ? '#C43670' : '#fff'"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>

          <span
            class="tab tab-explore block text-sm text-white mt-"
            :class="currentRouteName == 'search.resutls' ? 'text-nav-a' : ''"
            >البحث</span
          >
          <div
            v-show="currentRouteName == 'search.resutls'"
            class="bg-nava-a nav-tab"
          ></div>
        </router-link>
        <router-link to="/artists" class="router-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block"
            fill="none"
            viewBox="0 0 24 24"
            :stroke="currentRouteName == 'artists' ? '#C43670' : '#fff'"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 9l10.5-3m0 6.553v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 11-.99-3.467l2.31-.66a2.25 2.25 0 001.632-2.163zm0 0V2.25L9 5.25v10.303m0 0v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 01-.99-3.467l2.31-.66A2.25 2.25 0 009 15.553z"
            />
          </svg>

          <span
            class="tab tab-whishlist block text-sm text-white"
            :class="currentRouteName == 'artists' ? 'text-nav-a' : ''"
            >الفنانون</span
          >
          <div
            v-show="currentRouteName == 'artists'"
            class="bg-nava-a nav-tab"
          ></div>
        </router-link>
        <!-- <router-link v-show="getStatus"  to="/libarary" class="router-item hidden">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
         
            stroke-width="1.5"
            :stroke="currentRouteName == 'libarary' ? '#C43670' : '#fff'"
            class="h-6 w-6 inline-block mb-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z"
              />
            </svg>
         

          <span
            class="tab tab-account block text-sm text-white mt-"
            :class="currentRouteName == 'libarary' ? 'text-nav-a' : ''"
            >مكتبتي</span
          >
          <div
            v-show="currentRouteName == 'libarary'"
            class="bg-nava-a nav-tab"
          ></div>
        </router-link> -->
        <router-link  to="/profile" class="router-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24 "
            stroke-width="1.5"
            :stroke="currentRouteName == 'profile' ? '#C43670' : '#fff'"
            class="h-6 w-6 inline-block mb-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>

          <span
            class="tab tab-account block text-sm text-white mt-"
            :class="currentRouteName == 'profile' ? 'text-nav-a' : ''"
            >الإعدادات</span
          >
          <div
            v-show="currentRouteName == 'profile'"
            class="bg-nava-a nav-tab"
          ></div>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth";
import { mapState } from "pinia";
export default {
  data() {
    return {
      isActive: "home",
      currentRoute: "home",
    };
  },

  watch: {
    getStatus: {
      immediate: true,
      deep: true,
      /* eslint-disable */
      handler(newValue, oldValue) {
      
      },
    },
  },
  created() {
    this.currentRouteName;
  },
  computed: {
    ...mapState(useAuthStore, ["getStatus"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
</style>














