import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Vue3Toastify from 'vue3-toastify';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'


import './index.css'
import 'vue3-toastify/dist/index.css';
import '@/assets/fonts.css'; // Import the font CSS


const pinia = createPinia()
const app = createApp(App)

app.use(pinia)
    .use(Vue3Toastify, {
        autoClose: 3000,
    })
app.use(router)

app.mount('#app')


