<template>
  <div class="container md:mt-24 mt-6" @scroll="handleScroll">
    <main-header @search="search" :title="title" />

    <!-- todo make it slots -->

    <div class="flex justify-between my-6 mx-4 md:h-96 h-56 rounded-md">
      <slider-view />
    </div>
    <!-- start collections warper -->
    <!-- title -->
    <div v-for="(item, index) in getHomeCollections" :key="index">
      <div
        class="flex justify-between my-6 mx-4"
        dir="rtl"
        v-show="item.content_type == 'Track'"
      >
        <h2
          class="flex md:text-2xl text-lg font-bold text-white"
          v-if="!loaded"
        >
          <router-link :to="`/tracks/${item.title}`">
            {{ item.title }}</router-link
          >
          <span class="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="4"
              stroke="#C43670"
              class="md:w-6 md:h-6 h-4 w-4 mr-1 mt-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </span>
        </h2>
      </div>
      <!-- endof titile -->

      <div class="" v-if="!loaded" v-show="item.content_type == 'Track'">
        <songs-view :tracks="item.tracks" :collectionId="item.id" />
      </div>
    </div>
    <!-- end collections warper -->
    <!-- start of loaders -->
    <div>
      <title-header
        v-show="loaded"
        class="flex justify-start mx-4 space-x-3"
        dir="rtl"
      />
      <div
        v-if="loaded"
        class="grid grid-rows-1 grid-flow-col gap-4 mr-2"
        dir="rtl"
      >
        <songs-loader v-for="(i, index) in 6" :key="index" />
      </div>
    </div>
    <!-- end of loaders -->
    <div
      v-if="loaded"
      class="grid grid-rows-1 grid-flow-col gap-4 mr-2"
      dir="rtl"
    >
      <songs-loader v-for="(item, index) in 6" :key="index" />
    </div>
    <div class="flex justify-end my-6 mx-4 md:hidden" v-show="!loaded">
      <sub-adds />
    </div>
    <div class="flex flex-col md:flex-row md:justify-end w-full space-x-8 my-0">
      <div class="hidden md:block my-3 md:mt-6 md:w-full mr-6" dir="rtl">
        <h1
          class="text-white md:text-2xl text-lg font-bold flex"
          v-if="!loaded"
        >
          <router-link to="/artists"> اشهر الفنانين</router-link>
          <span class="" dir="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="4"
              stroke="#C43670"
              class="md:w-6 md:h-6 h-4 w-4 mr-1 mt-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </span>
        </h1>
        <title-header v-else />
        <div class="flex justify-start flex-wrap my-2" v-if="!loaded">
          <famous-artists
            v-for="artist in artistsData"
            :key="artist.id"
            :artist="artist"
          />
        </div>
        <div class="flex justify-start flex-wrap my-2" v-else>
          <famous-loader v-for="(item, index) in 6" :key="index" />
        </div>
      </div>
      <div class="mt-6 md:w-2/3">
        <div dir="rtl">
          <h1
            class="text-white lg:text-2xl md:text-xl text-lg font-bold flex mr-6"
            v-if="!loaded"
          >
            الرائجة الأن

            <span class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="4"
                stroke="#C43670"
                class="md:w-6 md:h-6 h-4 w-4 mr-1 mt-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </span>
          </h1>
          <title-header class="mr-4" v-else />
        </div>
        <ul
          class="divide-y divide-gray-600 dark:divide-gray-700 my-1 mr-6"
          dir="rtl"
        >
          <li class="text-lg text-white py-3" v-if="!loaded">
            <list-card
              v-for="track in tracksData"
              :key="track.id"
              :track="track"
            />
          </li>
          <li class="py-3" v-else>
            <list-loader v-for="(item, index) in 6" :key="index" />
          </li>
        </ul>
      </div>
    </div>
    <div class="md:hidden flex justify-between my-6 mx-4" dir="rtl">
      <h2 class="flex md:text-2xl text-lg font-bold text-white" v-if="!loaded">
        <router-link to="/artists"> اشهر الفنانين</router-link>

        <span class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="4"
            stroke="#C43670"
            class="md:w-6 md:h-6 h-4 w-4 mr-1 mt-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        </span>
      </h2>
      <title-header v-else />
    </div>
    <!-- endof titile -->
    <div class="md:hidden" v-if="!loaded">
      <cats-rounded :artists="artistsData" />
    </div>
    <div v-else class="grid grid-rows-1 grid-flow-col gap-4 mr-6" dir="rtl">
      <famous-swiper v-for="(item, index) in 6" :key="index" />
    </div>

    <sweet-loader :loading="loading" />
    <div class="my-1 mr-0" dir="rtl" v-if="getCatsData.length > 0">
      <div v-for="cat in getCatsData" :key="cat.id">
        <cat-headers
          class="my-3 mr-6"
          v-if="cat.tracks.length > 0"
          :loaded="true"
          :cat="cat"
        />
        <div dir="rtl">
          <genres-songs v-if="cat.tracks.length > 0" :tracks="cat.tracks" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useArtistsStore } from '@/stores/artists'
import { useCollectionStore } from '@/stores/collection'
import { useGenresStore } from '@/stores/genres'
import { useTracksStore } from '@/stores/tracks'
import { mapState, mapActions } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { useSearchtStore } from '@/stores/search'

import FamousArtists from '@/components/cards/FamousArtists.vue'
import ListCard from '@/components/cards/ListCard.vue'
import SongsView from '@/components/swipers/SongsView.vue'
import GenresSongs from '@/components/swipers/GenresSongs.vue'
import CatHeaders from '@/components/headers/CatHeaders.vue'
import CatsRounded from '@/components/swipers/CatsRounded.vue'
import SongsLoader from '@/components/loaders/SongsLoader.vue'
import ListLoader from '@/components/loaders/ListLoader.vue'
import FamousLoader from '@/components/loaders/FamousLoader.vue'
import TitleHeader from '@/components/loaders/TitleHeader.vue'
import FamousSwiper from '@/components/loaders/FamousSwiper.vue'
import SliderView from '@/components/swipers/SliderView.vue'
import SubAdds from '@/components/SubAdds.vue'
import MainHeader from '@/components/partials/MainHeader.vue'
import SweetLoader from '@/components/loaders/SweetLoader.vue'
export default {
  name: 'HomeView',
  components: {
    FamousArtists,
    ListCard,
    SongsView,
    GenresSongs,
    CatsRounded,
    SongsLoader,
    SliderView,
    FamousSwiper,
    ListLoader,
    FamousLoader,
    TitleHeader,
    SubAdds,
    MainHeader,
    CatHeaders,
    SweetLoader
  },
  data () {
    return {
      loaded: false,
      title: 'إكتشف',
      timer: false,
      currentPage: 0,
      loading: false
    }
  },
  mounted () {
    addEventListener('scroll', this.handleScroll, true)
  },
  computed: {
    ...mapState(useGenresStore, ['genresData', 'getCatsData', 'getMeat']),
    ...mapState(useArtistsStore, ['artistsData']),
    ...mapState(useTracksStore, ['tracksData']),
    ...mapState(useCollectionStore, ['getHomeCollections'])
  },
  methods: {
    ...mapActions(useGenresStore, ['showGenresData', 'showCatTracks']),
    ...mapActions(useArtistsStore, ['showArtistsData']),
    ...mapActions(useTracksStore, ['showTracksData']),
    ...mapActions(useAuthStore, [
      'subscribeAction',
      'checkSubescriptionStatus'
    ]),
    ...mapActions(useCollectionStore, ['showHomeData']),
    ...mapActions(useSearchtStore, ['showSearchResults']),
    async catsTracks () {
      await this.showCatTracks(this.Current + 1)
    },
    async handleScroll (e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target
      if (scrollTop + offsetHeight >= scrollHeight) {
        this.loading = true
        await this.catsTracks()

        this.loading = false
      }
    },

    async search (value) {
      try {
        await this.showSearchResults(1, 14, value).then(() =>
          this.$router.push({ name: 'search.resutls' })
        )
      } catch (e) {
        console.log(e)
      }
    },
    async subscribe () {
      await this.subscribeAction()
    }
  },
  async created () {
    this.loaded = true
    try {
      await this.showArtistsData()
      await this.showGenresData()
      await this.showTracksData()
      await this.showHomeData()
    } catch (e) {
      console.log(e)
    }
    this.loaded = false
  }
}
</script>
