
import gql from "graphql-tag";
/**
 * add track to favorite mutation
 * @param trackId 
 */
export const addFavoriteTrack = gql`
    mutation AddFavoriteTrack( $trackId: ID!) {
    addFavoriteTrack(trackId: $trackId) {
    success
    msg
    }
    }
`
/**
 * remove track from favorite mutation
 * @param trackId 
 */
export const removeFavoriteTrack = gql`
  mutation AddFavoriteTrack($trackId: ID!) {
  removeFavoriteTrack(trackId: $trackId) {
  success
  msg
  }
  }
`


//--------------------------------------------end of tracks manipulations-------------------------------------------
//******************************************************************************************************************
//------------------------------------------ start playlists actions------------------------------------------------



/**
 * create  playlist  
 * @param $input 
 * 
 */
export const createPlayList = gql`
  mutation AddFavoriteTrack($input: playlistInput) {
  createPlaylist(input: $input) {
  id
  user_id
  name
  is_public
  }
  }
`
/**
 * updating  playlist data   
 * @param $input ,@param updatePlaylistId
 * 
 */
export const updatePlayList = gql`
    mutation UpdatePlaylist($updatePlaylistId: ID!, $input: updatePlaylistInput) {
    updatePlaylist(id: $updatePlaylistId, input: $input) {
    id
    is_public
    name
    }
    }
`
export const removeAllPlayLists = gql`
  mutation RemoveAllFavoritePlaylists {
  removeAllFavoritePlaylists {
  msg
  success
  }
  }
`
export const Favorite = gql`
  mutation DeletePlaylist($playlistId: ID!) {
  deletePlaylist(playlistId: $playlistId) {
  error
  msg
  }
  }
`
export const addPlaylistToFavorite = gql`
  mutation AddFavoritePlaylist($playlistId: ID!) {
  addFavoritePlaylist(playlistId: $playlistId) {
  msg
  success
  }
  }
`

/**
 * add track to a playlist 
 * @param playlistId @param trackId
 * 
 */

export const addTrackToAplayList = gql`
  mutation AddFavoriteTrack($playlistId: ID!, $trackId: ID!) {
  addPlaylistTrack(playlistId: $playlistId, trackId: $trackId) {
  error
  msg
  }
  }
`


/**
 * remove track from a play list 
 * @params $playListId $trackId
 */

export const removePlayListTrack = gql`
    mutation AddFavoriteTrack($playlistId: ID!, $trackId: ID!) {
    removePlaylistTrack(playlistId: $playlistId, trackId: $trackId) {
    error
    msg
    }
    }
`
export const removeAllFavoritePlayLists = gql`
    mutation RemoveAllFavoritePlaylists {
    removeAllFavoriteTracks {
    msg
    success
    }
    }
`
export const removeFavoritePlayList = gql`
  mutation RemoveFavoritePlaylist($playlistId: ID!) {
  removeFavoritePlaylist(playlistId: $playlistId) {
  msg
  success
  }
  }
`

export const removeAllplayListTracks = gql`
    mutation RemoveAllFavoritePlaylists($playlistId: ID!) {
    removeAllPlaylistTracks(playlistId: $playlistId) {
    error
    msg
    }
    }
`

export const removepls = gql`

mutation DeletePlaylist($playlistId: ID!) {
  deletePlaylist(playlistId: $playlistId) {
    error
    msg
  }
}
`
//------------------------------------------ end of playlists actions------------------------------------------------

//--------------------------------------------start of artists actions------------------------------------------------

export const followArtist = gql`
    mutation FollowArtist($artistId: String!) {
    followArtist(artistId: $artistId) {
    error
    msg
    }
    }
`

export const unFollowArtist = gql`
    mutation UnFollowArtist($artistId: String!) {
    unFollowArtist(artistId: $artistId) {
    error
    msg
    }
    }
`


//--------------------------------------------end of artists actions------------------------------------------------
