import HomeView from '../views/HomeView.vue'
import AbrofSubscribtion from '../views/AbrofSubscribtion.vue'

export const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/categories',
    name: 'categories',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "categories" */ '../views/CategoriesView.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/artists',
    name: 'artists',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "artists" */ '../views/ArtistsView.vue'),
    meta: {
      auth: true
    }
  },

  {
    path: '/tracks/:title',
    name: 'tracks',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tracks" */ '../views/TracksView.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/playList/:id',
    name: 'playlist',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "playlist" */ '../views/PlayList.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/artist/show/:id',
    name: 'artist.show',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "showArtist" */ '../views/ShowArtist.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/show/catgories/:id',
    name: 'show.catgories',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cat" */ '../views/CatDetails.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/search/results',
    name: 'search.resutls',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Youth" */ '../views/SearchResults.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/libarary',
    name: 'libarary',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lib" */ '../views/LibView.vue'),
    meta: {
      auth: true
    }

  },
  {
    path: '/subscribe',
    name: 'subscribe',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "abrofSub" */ '../views/auth/SubscribeView.vue')

  },
  {
    path: '/profile',
    name: 'profile',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "abrofSub" */ '../views/auth/ProfileView.vue')

  }
  ,
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/LoginView.vue')

  }
  ,
  {
    path: '/login/subscribe_ok/:msisdn',
    name: 'abrof.subscribe',
   
    component: AbrofSubscribtion

  },

  {
    path: '/success',
    name: 'success',
    component: () => import(/* webpackChunkName: "about" */ '../views/SuccessView.vue')
  }
]

