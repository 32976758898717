import { defineStore,acceptHMRUpdate } from "pinia";
import apolloClient from "../plugins/apollo";
import { addFavoriteTrack, removeFavoriteTrack, createPlayList, addTrackToAplayList, updatePlayList, removepls, removePlayListTrack } from "@/gqls/mutations";
import { getPlayLists } from "@/gqls/auth";
import { useTracksStore } from "./tracks";
// import { storeToRefs } from "pinia";
export const useFavoriteStore = defineStore("favorite", {
    state: () => {
        return {
            playLists: Object
        }
    },
    getters: {
        getUserPlayLists: (state) => state.playLists
    },
    actions: {

        /***
         * add single track to favorite
         * @param id $trackId
         */
        async addFavoriteTrackAction(id) {
            return new Promise((resolve) => {
                apolloClient
                    .mutate({
                        mutation: addFavoriteTrack,

                        variables: {
                            trackId: id
                        }
                    }).then(async response => {

                        resolve(response)
                    })
            })


        },//end of add track to favorite

        async addTrackToplayList(TrackID, plsId) {
           
            return new Promise((resolve) => {
                apolloClient
                    .mutate({
                        mutation: addTrackToAplayList,

                        variables: {
                            playlistId: plsId,
                            trackId: TrackID
                        }
                    }).then(async response => {

                        resolve(response),
                        await this.addenwItemInthePlaylist(response)
                    })
            })
            
        },
        async addenwItemInthePlaylist(response){
            if(response.data){
                const trackStore = useTracksStore()
                let data = [];
                data = trackStore.playLists;
                console.log(data,trackStore.playLists,response.data)
                // trackStore.favoriteTracks = [...data]
            }
          
        },
        /***
        * remove single track to favorite
        * @param id $trackId
        */
        async remove(id) {
          
             const trackStore = useTracksStore()
             console.log("before", trackStore.getFavoriteTracks)

            let data = trackStore.getFavoriteTracks;

            return new Promise((resolve) => {
                apolloClient
                    .mutate({
                        mutation: removeFavoriteTrack,

                        variables: {
                            trackId: id
                        }
                    }).then(async response => {
                        console.log(response)
                        trackStore.$patch(state => {
                            state.favoriteTracks = data.filter((el) => el.id !== id);

                        })
                        console.log("after", trackStore.getFavoriteTracks)


                        resolve(response)
                    })
            })

            
            /**
             * 
             * const trackStore = useTracksStore()
            let data = [];
            data = trackStore.favoriteTracks.filter((el) => el.id !== id);
            trackStore.favoriteTracks = [...data]

            return new Promise((resolve) => {
                apolloClient
                    .mutate({
                        mutation: removeFavoriteTrack,

                        variables: {
                            trackId: id
                        }
                    }).then(async response => {

                        resolve(response)
                    })
            })
             */


        },//end of removing single track 


        /***
         * create  playList 
         * @params name,is_public
         */
        async createPlayListAction(input) {
            return new Promise((resolve) => {
                apolloClient
                    .mutate({
                        mutation: createPlayList,

                        variables: {
                            input: input
                        }
                    }).then(async response => {

                        resolve(response)
                    })
            })


        },
        /***
         * update  playList 
         * @params name,is_public
         */
        async updatePlayListAction(input, playlistId) {
            return new Promise((resolve) => {
                apolloClient
                    .mutate({
                        mutation: updatePlayList,

                        variables: {
                            input: input,
                            updatePlaylistId: playlistId
                        }
                    }).then(async response => {

                        resolve(response)
                    })
            })


        },
        /***
        * remove playlist track   
        * @params playListId,trackId
        */
        async removeTrackFromPlaylist(playlistId, trackId) {
            return new Promise((resolve) => {
                apolloClient
                    .mutate({
                        mutation: removePlayListTrack,

                        variables: {
                            trackId: trackId,
                            playlistId: playlistId
                        }
                    }).then(async response => {

                        resolve(response)
                    })
            })


        },

        /***
        * remove single track to favorite
        * @param id $trackId
        */
        async removePlayListAction(playlistId) {
            return new Promise((resolve) => {
                apolloClient
                    .mutate({
                        mutation: removepls,

                        variables: {
                            playlistId: playlistId
                        }
                    }).then(async response => {

                        resolve(response)
                    })
            })


        },//end of removing single track 
        /**
         * get all playlists names and id's
         */
        async getPlaylistsData() {
            return new Promise((resolve) => {
                apolloClient
                    .mutate({
                        mutation: getPlayLists,
                        variables: {
                            page: 1,
                            limit: 100
                        }
                    }).then(async response => {
                        this.playLists = [...response.data.getPlaylists.data.filter((pls) => pls.is_public === false)]
                        resolve(response)
                    })
            })
        }
    }
})
if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(acceptHMRUpdate(useFavoriteStore, import.meta.webpackHot))
  }